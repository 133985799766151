.home-container {
  width: 100%;

  @media screen and (max-width: 1200px) {
    .inner-header-container {
      padding: 0 4%;
    }
  }

  .inner-header-container {
    margin-bottom: 12px;
    border-bottom: 1px solid rgb(234, 234, 234);
    width: 100%;
    background-color: white;
    height: 64px;
  }

  @media screen and (max-width: 768px) {
    .content-container {
      // padding: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .content-container {
      padding: 0 4%;
    }
  }

  @media screen and (min-width: 1200px) {
    .inner-header-container {
      padding: 0 16%;
    }

    .content-container {
      padding: 0 16%;
    }
  }

  @media screen and (min-width: 1200px) {
    .content-container {
      display: flex;

      .horizontal-category {
        display: none;
      }
    }
  }

  .content-container {
    // 中等
    @media screen and (max-width: 1200px) {
      .horizontal-category {
        background-color: white;
        padding: 24px 24px 0px 24px;
        display: flex;
        width: 100%;
      }

      .content-category-container {
        display: none;
      }
    }

    .content-category-container {
      width: 200px;
      margin-right: 4%;
    }
  }

  .article-list-container {
    flex: 1;
    background-color: white;
    padding: 24px 24px 0px 24px;

    @media screen and (min-width: 768px) {
      .article-item-container {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgb(234, 234, 234);
      }
    }

    @media screen and (max-width: 768px) {
      .article-item-container {
        padding-bottom: 8px;
        margin-bottom: 18px;
        border-bottom: 1px solid rgb(234, 234, 234);
      }
    }

    .no-data {
    }
  }
}
