@base-color: #ffffff;
@base-text-color:black;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
}

.main {
  padding-bottom: 10vh;
  overflow: hidden;
  font-size: 16px;
}

.base-font {
  font-size: 16PX;
}

#root{
  font-size: 14px;
}

.common-center-flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

pre{
  border-radius: 4px;
  padding: 24px;
  background-color: rgb(243, 243, 243);
}