.article-container {
  padding-bottom: 100px;
  .article-header-container {
    height: 64px;
    background-color: white;
    border-bottom: 1px solid rgb(239, 239, 239);
  }

  .article-content-container {
    padding: 4%;

    .article-image-container {
      height: 30vh;
      img {
        border-radius: 12px;
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .title-container {
      margin-top: 24px;
      font-size: 24px;
      width: 100%;
    }

    .subTitle-container {
      margin-top: 18px;
      font-size: 16px;
      border-left: 3px solid rgb(72, 133, 225);
      padding-left: 12px;
    }

    .time-container {
      display: flex;
      justify-content: space-between;
      color: gray;
      margin-top: 14px;
      border-bottom: 1px solid rgb(224, 224, 224);
      padding-bottom: 12px;
    }

    .webview-container{

      iframe{
        width: 100%;
        height: 100%;
      }
    }

    .text-container {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .article-container {
    .navigator-container {
      display: none;
    }
    .article-header-container {
      padding: 0 4%;
    }

    .article-content-container {
      background-color: white;
      .article-image-container {
        height: 240px;
        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .title-container {
        font-size: 16px;
        width: 100%;
      }

      .subTitle-container {
        margin-top: 18px;
        font-size: 14px;
        border-left: 3px solid rgb(72, 133, 225);
        padding-left: 12px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .article-container {
    .navigator-container {
      display: none;
    }
    .article-header-container {
      padding: 0 8%;
    }

    .article-content-container {
      margin: 2% 8% 0 8%;
      background-color: white;
      .article-image-container {
        height: 300px;
        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .article-container {
    position: relative;
    .navigator-container {
      position: fixed;
      right: 36px;
      top: 64px;
      width: 300px;
      padding: 2%;
      margin-top: 2%;
      background-color: white;
      max-height: 60vh;

      .navigator-title {
        font-size: 18px;
        padding-bottom: 4px;
        border-bottom: 1px solid rgb(231, 231, 231);
        margin-bottom: 12px;
        font-weight: 500;
      }

      .navigator-item-common {
        margin-bottom: 12px;

        &:hover {
          color: rgb(47, 127, 218);
          cursor: pointer;
        }
      }

      .navigator-item-h1 {
        padding-left: 0;
        font-size: 16px;
      }

      .navigator-item-h2 {
        padding-left: 12px;
      }
    }

    .article-header-container {
      padding: 0 12%;
    }

    .article-content-container {
      margin: 2% 18% 0 18%;
      background-color: white;

      .article-image-container {
        height: 400px;
        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
