.article-card-container {
  .article-card-right {
    // overflow: hidden;
    text-overflow: ellipsis; //溢出用省略号显示
  }
}

@media screen and (min-width: 768px) {
  .article-card-container {
    width: 100%;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    height: 20vh;
    .article-card-left {
      width: 40%;
      height: 100%;
      margin-right: 20px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .article-card-right {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title-text-container {
        height: calc(100% - 36px);
        overflow: hidden;

        .title {
          max-height: 50%;
          overflow: hidden;
          margin-bottom: 12px;
          font-size: 16px;
        }

        .subTitle {
          font-size: 12px;
          font-weight: 400;
          color: gray;
        }
      }

      .bottom-line {
        height: 28px;
        display: flex;
        align-items: center;
        color: grey;
        justify-content: space-between;

        .label-container{
          display: flex;
        }

        .svg-box {
          img {
            height: 80%;
          }
        }
      }
    }
  }

  .article-card-container:hover {
    cursor: pointer;
    scale: 1.018;
    box-shadow: inset;
  }
}

@media screen and (max-width: 768px) {
  .article-card-container {
    .article-card-left {
      width: 100%;
      height: 180px;
      margin-bottom: 8px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .article-card-right {
      .title {
        font-size: 18px;
      }

      .subTitle {
        display: none;
      }

      .bottom-line {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
