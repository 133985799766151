@import "../../global.less";

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
    background-color: white;
  .left-logo {
    position: relative;
  }

  .header-right {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;

    img {
      width: 36px;
    }
  }
}
