@import "../../../../global.less";

.category-container {
  width: 100%;

  .common {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 6px 10px;
    border-radius: 4px;
    font-weight: 500;
    transition: all 0.3s;
    &:hover {
      background-color: @base-color;
      cursor: pointer;
      color: @base-text-color;
    }

    .num {
      padding: 2px 8px;
      border-radius: 4px;
      color: @base-text-color;
      background-color: @base-color;
    }
  }

  .active-item {
    color: @base-text-color;
    background-color: @base-color;
  }
}
